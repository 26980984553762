import {
  Paper,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  Pagination,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDois, fetchDoi, fetchDoiByName } from "../../store/functions/fetchData";
import { useNavigate } from "react-router-dom";
import useHandleDoi from "../../hooks/useHandleDoi";
import { doiActions } from "../../store/doiSlice";
import { useRef } from "react";
import { Box } from "@mui/system";

//component to render the available dois for editing purposes
const DoiChoice = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token.token);
  const dois = useSelector((state) => state.doi.dois);
  let total = useSelector((state) => state.doi.total);
  const currentPage = useSelector((state) => state.doi.currentPage);
  const [page, setPage] = useState(currentPage);
  const { exitDoi } = useHandleDoi();
  const numberOfPages = useSelector((state) => state.doi.numberOfPages);
  const doisPerPage = useSelector((state) => state.doi.doisPerPage);

  const [isDoiSearched, setIsDoiSearched] = useState(dois?.length === 1 ? dois[0].doi : null);

  const doiSearchRef = useRef();

  useEffect(() => {
    if (token && !isDoiSearched) dispatch(fetchDois(page, doisPerPage, total));
    if (token && isDoiSearched) searchDOI(isDoiSearched);
  }, [isDoiSearched, page]);

  // clear the doi and the issues when the user enter the page
  useEffect(() => {
    exitDoi();
  }, []);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const searchDOI = (value) => {
    dispatch(fetchDoiByName(value, setPage));
  };

  const RenderTable = () => {
    const navigate = useNavigate();
    if (!Array.isArray(dois)) return <CircularProgress />;
    return (
      <TableContainer component={Paper}>
        <Table padding="normal" sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">DOI</TableCell>
              <TableCell align="center">Total amount of issues</TableCell>
              <TableCell align="center">Total amount of unlabelled issues</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dois.map((row, index) => (
              <TableRow
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                hover
                key={row.uuid}
                onClick={() => {
                  dispatch(doiActions.changePage(page));
                  dispatch(fetchDoi(token, row.uuid, index));
                  navigate("/comment");
                }}
              >
                <TableCell scope="row">{row.doi}</TableCell>
                <TableCell align="center">{row.total_issues}</TableCell>
                <TableCell align="center">{row.total_unlabeled_issues}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const SearchBar = () => {
    return (
      <form
        style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: 3 }}
        onSubmit={(e) => {
          e.preventDefault();
          setIsDoiSearched(doiSearchRef.current.value);
          searchDOI(doiSearchRef.current.value);
        }}
      >
        <Box type="submit" style={{ display: "flex", alignItems: "center", gap: 2 }}>
          <TextField variant="filled" label="Search DOI" inputRef={doiSearchRef} />
          <IconButton type="submit" aria-label="search">
            <SearchIcon style={{ fill: "blue" }} />
          </IconButton>
        </Box>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            setIsDoiSearched(null);
          }}
        >
          Back to all DOIs
        </Button>
      </form>
    );
  };
  return (
    <Container maxWidth="lg" sx={{ margin: "50px auto" }}>
      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
        }}
      >
        Select DOI to edit
      </Typography>
      <Box sx={{ display: "flex", my: 2, justifyContent: "space-between", alignItems: "end" }}>
        <Pagination count={numberOfPages} page={page} onChange={handleChange} />
        <SearchBar />
      </Box>
      <RenderTable />
    </Container>
  );
};

export default DoiChoice;
