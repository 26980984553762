import { createSlice } from "@reduxjs/toolkit";

//slice which holds all dois and current doi as objects
const doiSlice = createSlice({
  name: "doi",
  initialState: {
    dois: null,
    doi: null,
    total: 0,
    issues: null,
    currentPage: 1,
    doisPerPage: 50,
    currentDoisPerPage: 0,
    numberOfPages: 1,
    index: null,
    newDoiIncoming: true,
  },
  reducers: {
    assignDois(state, action) {
      state.dois = [...action.payload];
    },
    assignIssues(state, action) {
      state.issues = [...action.payload];
      console.log(state.issues);
    },
    changePage(state, action) {
      state.currentPage = action.payload;
    },
    assignCurrentDoisPerPage(state, action) {
      state.currentDoisPerPage = action.payload;
    },
    assignTotal(state, action) {
      state.total = action.payload;
      state.numberOfPages =
        state.total % state.doisPerPage === 0
          ? state.total / state.doisPerPage
          : Math.ceil(state.total / state.doisPerPage);
    },
    assignDoi(state, action) {
      state.doi = action.payload;
      state.newDoiIncoming = false;
    },
    assignIndex(state, action) {
      state.index = action.payload;
    },
    assignWait(state) {
      state.newDoiIncoming = true;
    },
    deassignDoi(state) {
      state.doi = null;
      state.index = null;
      state.issues = null;
      state.newDoiIncoming = true;
    },
  },
});

export const doiActions = doiSlice.actions;

export default doiSlice;
